/* Popular Restaurants Section Styling */
.popular-restaurants-section {
  background-color: rgba(47, 79, 79, 1); /* Dark slate gray background */
  padding: 5vw 0; /* Scales padding with screen */
  text-align: center;
}

/* Section Title */
.section-title {
  font-size: 3vw; /* Scales dynamically */
  font-weight: bold;
  color: rgba(255, 228, 196, 1);
  margin-bottom: 3vw;
  font-family: Playfair Display;
}

/* Restaurant Card Styling */
.restaurant-card {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  width: 15vw; /* Scales dynamically */
  height: 18vw; /* Scales dynamically */
  margin: 0 auto;
}

.restaurant-card:hover {
  transform: translateY(-1vw);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Restaurant Image */
.restaurant-image {
  width: 100%;
  height: 10vw;
  object-fit: cover;
  margin-top: 5px;
}

/* Restaurant Info */
.restaurant-info {
  padding: 1vw;
  text-align: center;
}

/* Restaurant Name */
.restaurant-name {
  font-size: 1.2vw;
  font-weight: bold;
  color: #2f4f4f;
  margin-bottom: 0.8vw;
}

/* Restaurant Location */
.restaurant-location {
  font-size: 1vw;
  color: #777;
}

/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Content */
.popup-content {
  background-color: #fff;
  border-radius: 10px;
  width: 90vw;
  max-width: 1200px;
  height: 90vh;
  max-height: 800px;
  overflow-y: auto;
  padding: 3vw;
  position: relative;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 1vw;
  right: 1vw;
  background: none;
  border: none;
  font-size: 1.5vw;
  cursor: pointer;
  color: #2f4f4f;
  padding: 1vw; /* Add padding for better touch area */
}

.close-button:hover {
  color: #ff6347;
}
/* Popup Grid Layout */
.popup-grid {
  display: flex;
  gap: 2vw;
  flex-wrap: wrap;
}

/* Left Side */
.popup-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 2vw;
  padding-top: 1vw;
}

.restaurant-logo-info {
  display: flex;
  align-items: center;
  gap: 2vw;
}

.partner-image {
  width: 8vw;
  height: 8vw;
  border-radius: 10px;
}

.partner-name {
  font-size: 1.5vw;
  color: #2f4f4f;
}

.location-info {
  font-size: 1vw;
  color: #777;
}

.map-image img {
  width: 40vw;
  height: 25vw;
  border-radius: 10px;
  object-fit: contain;
}

/* Right Side */
.popup-right {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 2vw;
  padding-top: 3vw;
}

.menu-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.btn-coupon {
  align-self: flex-end;
  padding: 1vw 2vw;
  background-color: #ff6347;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-coupon:hover {
  background-color: #e5533d;
}

/* Coupon Section */
.coupon-image {
  max-width: 100%;
  height: auto;
}

.download-coupon-button, 
.back-button {
  background-color: #FF5722;
  color: #fff;
  border: none;
  padding: 1vw 2vw;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1vw;
}

.download-coupon-button:hover, 
.back-button:hover {
  background-color: #E64A19;
}

.coupon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
}

.coupon-image {
  max-width: 100%;
  height: auto;
  max-height: 70vh;
  object-fit: contain;
  margin-bottom: 2vw;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .restaurant-card {
    width: 18vw;
    height: 20vw;
  }

  .popup-content {
    width: 95vw;
    height: 85vh;
  }

  .popup-grid {
    flex-direction: column;
    align-items: center;
  }

  .partner-image {
    width: 10vw;
    height: 10vw;
  }

  .map-image img {
    width: 45vw;
    height: 30vw;
  }

  .btn-coupon {
    padding: 1.2vw 2.5vw;
  }
}

@media (max-width: 768px) {
  .restaurant-card {
    width: 22vw;
    height: 24vw;
  }

  .popup-content {
    width: 95vw;
    height: 85vh;
  }

  .popup-grid {
    flex-direction: column;
  }

  .partner-image {
    width: 12vw;
    height: 12vw;
  }

  .map-image img {
    width: 50vw;
    height: 35vw;
  }

  .btn-coupon {
    padding: 1.5vw 3vw;
  }
}

@media (max-width: 480px) {
  .restaurant-card {
    width: 26vw;
    height: 28vw;
  }

  .popup-content {
    width: 95vw;
    height: 80vh;
    padding: 4vw;
  }

  .popup-grid {
    flex-direction: column;
    align-items: center;
  }

  .partner-image {
    width: 14vw;
    height: 14vw;
  }

  .map-image img {
    width: 55vw;
    height: 40vw;
  }

  .btn-coupon {
    padding: 2vw 3.5vw;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .popup-grid {
    flex-direction: column;
  }

  .popup-content {
    width: 95%;
    height: 95%;
    padding: 15px;
  }

  .popup-left, .popup-right {
    width: 100%;
  }

  .map-image img {
    width: 100%;
    height: auto;
    max-height: 300px;
  }

  .menu-image img {
    width: 100%;
    height: auto;
  }

  .partner-image {
    width: 80px;
    height: 80px;
  }

  .partner-name {
    font-size: 1.2rem;
  }

  .location-info {
    font-size: 0.9rem;
  }

  .btn-coupon {
    align-self: center;
    width: 100%;
    margin-top: 15px;
  }

  .coupon-container {
    max-width: 95%;
  }
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .restaurant-card {
    width: 18vw;
    height: 20vw;
  }

  .popup-content {
    width: 95vw;
    height: 85vh;
  }

  .popup-grid {
    flex-direction: column;
    align-items: center;
  }

  .partner-image {
    width: 10vw;
    height: 10vw;
  }

  .map-image img {
    width: 45vw;
    height: 30vw;
  }

  .btn-coupon {
    padding: 1.2vw 2.5vw;
  }
}

@media (max-width: 768px) {
  .restaurant-card {
    width: 22vw;
    height: 24vw;
  }

  .popup-content {
    width: 95vw;
    height: 85vh;
  }

  .popup-grid {
    flex-direction: column;
  }

  .partner-image {
    width: 12vw;
    height: 12vw;
  }

  .map-image img {
    width: 50vw;
    height: 35vw;
  }

  .btn-coupon {
    padding: 1.5vw 3vw;
  }
}

@media (max-width: 480px) {
  .restaurant-card {
    width: 26vw;
    height: 28vw;
  }

  .popup-content {
    width: 95vw;
    height: 80vh;
    padding: 4vw;
  }

  .popup-grid {
    flex-direction: column;
    align-items: center;
  }

  .partner-image {
    width: 14vw;
    height: 14vw;
  }

  .map-image img {
    width: 55vw;
    height: 40vw;
  }

  .btn-coupon {
    padding: 2vw 3.5vw;
  }
}

@media (max-width: 768px) {
  .close-button {
    font-size: 6vw; /* Larger font size for mobile */
    top: 2vw;
    right: 2vw;
    padding: 2vw; /* Larger touch area */
  }
}

/* Responsive Adjustments for Mobile Screens */
@media (max-width: 768px) {
  .restaurant-image {
    height: 20vw; /* Increase height for better visibility on mobile */
  }

  .restaurant-card {
    width: 40vw; /* Adjust card width for mobile */
    height: 50vw; /* Adjust card height for mobile */
  }

  .restaurant-name {
    font-size: 4vw; /* Increase font size for better readability */
  }

  .restaurant-location {
    font-size: 3vw; /* Increase font size for better readability */
  }
}

@media (max-width: 480px) {
  .restaurant-image {
    height: 25vw; /* Further increase height for very small screens */
  }

  .restaurant-card {
    width: 60vw; /* Adjust card width for very small screens */
    height: 70vw; /* Adjust card height for very small screens */
  }

  .restaurant-name {
    font-size: 5vw; /* Further increase font size */
  }

  .restaurant-location {
    font-size: 4vw; /* Further increase font size */
  }
}

/* Responsive Adjustments for Mobile Screens */
@media (max-width: 768px) {
  .section-title {
    font-size: 6vw; /* Larger font size for mobile */
    margin-bottom: 5vw; /* Increase margin for better spacing */
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 8vw; /* Even larger font size for very small screens */
    margin-bottom: 6vw; /* Further increase margin */
  }
}