/* Home Container */
.home-container {
  font-family: Playfair Display;
  color: #FFE4C4;
  width: 100%;
  overflow: hidden;
}

/* Hero Container - Ensures About Section Stays Inside */
.hero-container {
  position: relative;
  width: 100%;
  height: 90vh;
}

.hero-subtitle {
 font-size: 50px;
 color: #2F4F4F;
 font-weight: bold;
}

/* About Section (Scales Responsively, Stays Center-Left) */
.about-section {
  position: absolute;
  top: 35vh; /* Adjusted to scale with screen height */
  width: 40vw; /* Dynamic width */
  max-width: 600px;
  border-radius: 10px;
  padding: 3vw 3vw; /* Scales with screen */
  text-align: left;
  z-index: 2;
  transition: all 0.3s ease-in-out;
}

/* About Content */
.about-content {
  width: 100%;
}

/* About Text (Scales Dynamically) */
.about-text {
  font-size: 1.5vw; /* Scales with screen */
  line-height: 1.6;
  color: #2F4F4F;
  margin-bottom: 1.5vw;
  text-align: left;
}


.btn-custom-primary {
  padding: 10px 20px;
  border-radius: 120px;
  background-color: #FFE4C4;
  color: black;
  border: 1px solid #FF6347;
  cursor: pointer;
  transition: 0.3s ease;
}


/* Buttons Container */
.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1vw;
  width: 100%;
}


.btn-custom-secondary {
  padding: 10px 20px;
  border-radius: 120px;
  background-color: #FF6347;
  color: #FFE4C4;
  border: 1px solid #FFE4C4;
  cursor: pointer;
  transition: 0.3s ease;
}

 
/* Optional: Hover Effect */
.btn-custom-secondary:hover {
  border: 1px solid #FF6347; /* Changes border color on hover */
  color: var(--Neutral-01, #2F4F4F);
  background-color: #FFE4C4;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .about-section {
    width: 50vw;
    height: fit-content;
    padding: 3vw 3vw;
  }

  .about-text {
    font-size: 1.8vw;
  }

  .btn-custom-primary-hero, 
  .btn-custom-secondary-hero {
    width: 14vw;
    font-size: 1.2vw;
  }
}

@media (max-width: 768px) {
  .about-section {
    width: 75vw;
    height: fit-content;
    padding: 3vw 3vw;
  }

  .about-text {
    font-size: 3vw;
  }

  .button-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .btn-custom-primary-hero, 
  .btn-custom-secondary-hero {
    width: 16vw;
    font-size: 2vw;
  }
}

@media (max-width: 480px) {
  .about-section {
    width: 75vw;
    padding: 3vw 3vw;
  }

  .about-text {
    font-size: 4vw;
  }

  .btn-custom-primary-hero, 
  .btn-custom-secondary-hero {
    width: 18vw;
    font-size: 1.8vw;
  }
}