/* Hero Section */
.hero-section {
  position: relative;
  width: 100%;
  height: 90vh;
  background-image: url('../assets/Home3.jpeg'); /* Default background */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding: 20px;
  overflow: hidden;
}



/* Hero Content */
.hero-content {
  position: relative;
  z-index: 1;
  color: #2F4F4F;
  font-family: "Playfair Display", serif;
  text-align: left;
  padding: 40px 20px;
  width: 90%;
  max-width: 800px;
}

/* Hero Title */
.hero-title {
  font-size: 4vw;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
  color: #2F4F4F;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .hero-title {
    font-size: 4vw;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 3.5vw;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 3.5vw;
  }
}