/* 🌟 Base Footer Styles */
.footer {
  background: #2F4F4F;
  color: #FFE4C4;
  padding: 40px 20px;
  position: relative;
  bottom: 0;
  width: 100%;
}
 
.container {
  max-width: 1200px;
  margin: 0 auto;
}
 
/* Footer Row (Desktop Layout) */
.row-custom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
 
/* Footer Sections */
.footer-section {
  margin-bottom: 20px;
}
 
/* Footer Logo */
.footer-logo {
  width: 250px;
  margin-bottom: 10px;
  padding-right: 20px;
}
 
/* Social Links Container */
.social-links {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 15px;
}

/* Default Instagram Icon - White */
.instagram-icon {
  color: #FFFFFF; /* White color */
  font-size: 18px;
  transition: 0.3s ease-in-out;
  border-radius: 50%;
  background-color: #FF6347;
  padding: 5px;
}

/* Hover Effect - Turns Red */
.instagram-icon:hover {
  color: #FF0000; /* Red on hover */
}


/* Default LinkedIn Icon - White */
.linkedin-icon {
  color: #FFFFFF; /* White by default */
  font-size: 18px;
  transition: 0.3s ease-in-out;
  border-radius: 50%;
  background-color: rgb(0, 68, 255);
  padding: 5px;
}

/* Hover Effect - Turns Red */
.linkedin-icon:hover {
  color: #FF0000; /* Red on hover */
}

/* Footer Links */
.footer-links {
  list-style: none;
}
 
.footer-links li {
  margin-bottom: 8px;
}
 
.footer-links a {
  color: #FFE4C4;
  text-decoration: none;
  transition: 0.3s;
}
 
.footer-links a:hover {
  color: #FF6347!important; /* Optional: Gold hover effect */
  text-decoration: underline !important;
}
 
/* Pages & Utility Pages - Separate Columns on Web */
.middle-section-custom {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
 
.footer-pages,
.footer-utility {
  width: 50%;
}
 
/* Footer Images */
.footer-images {
  display: flex;
  gap: 10px;
}
 
.footer-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
}
 
/* Copyright Text */
.copyright-text {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #aaa;
}
 
 
.footer-form {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between input and button */
  width: 100%;
}
 
.footer-input {
  flex: 1; /* Takes up remaining space */
  padding: 10px;
  border-radius: 120px;
  border: 2px solid #FF6347;  /* ✅ Orange border */
  color: #222; /* Text color for better contrast */
  width: auto;
  background-color: #FFE4C4;
  transition: border-color 0.3s ease; /* Smooth transition */
}
 
/* 🔹 Optional: Change border color on focus */
.footer-input:focus {
  border-color: #FF4500; /* Darker orange on focus */
  outline: none;
}
 
.footer-input::placeholder {
  color: #bbb;
}
 
.btn-custom-secondary {
  padding: 10px 20px;
  border-radius: 120px;
  background-color: #FF6347;
  color: #FFE4C4;
  border: 1px solid #FFE4C4;
  cursor: pointer;
  transition: 0.3s ease;
}

 
/* Optional: Hover Effect */
.btn-custom-secondary:hover {
  border: 1px solid #FF6347; /* Changes border color on hover */
  color: var(--Neutral-01, #2F4F4F);
  background-color: #FFE4C4;
}
 
 
/* 📱 MOBILE & TABLET RESPONSIVENESS (Only Modify Layout, Keep Same Design) */
@media (max-width: 991px) {
  /* Stack Footer Sections */
  .row-custom {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
 
  /* Adjust Logo Size */
  .footer-logo {
    width: 40px;
  }
 
  /* Center Social Icons */
  .social-links {
    justify-content: center;
  }
 
  /* Stack Footer Links */
  .middle-section-custom {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }
 
  .footer-pages,
  .footer-utility {
    width: 100%;
  }
 
  /* Footer Images Wrap Correctly */
  .footer-images {
    justify-content: center;
    flex-wrap: wrap;
  }
 
  .footer-image {
    width: 70px;
    height: 70px;
  }
 
  /* Add Padding to Prevent Overlap */
  .footer {
    padding-bottom: 20px;
  }
}
/* I added the footer mobile responsive code below, just comment out the below code to get the old result --- Rahul Sharma */
@media (max-width: 768px) {
  .footer {
    padding: 30px 15px;
  }

  .footer-logo {
    width: 150px;
    margin-bottom: 20px;
  }

  .footer-section {
    width: 100%;
    margin-bottom: 25px;
    text-align: center;
  }

  .footer-form {
    flex-direction: column;
    gap: 15px;
  }

  .footer-input,
  .btn-custom-secondary {
    width: 100%;
    max-width: 300px;
  }

  .social-links {
    justify-content: center;
    margin: 15px 0;
  }

  .footer-images {
    gap: 8px;
  }

  .footer-image {
    width: 60px;
    height: 60px;
  }
}