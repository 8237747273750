/* Team.css */

/* Main Team Section */
.team-section {
  background-color: #F5ECD3;
  padding: 5px 0;
  width: 100%;
}


.team-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2F4F4F;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.team-image {
  width: 150px; /* Adjust size as needed */
  height: 150px; /* Adjust size as needed */
  border-radius: 50%; /* Make the image circular */
  object-fit: cover; /* Ensure the image covers the area */
  border: 4px solid #2F4F4F; /* Dark slate gray border */
  margin-bottom: 20px;
}

.team-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2F4F4F; /* Dark slate gray */
  margin-bottom: 10px;
}

.team-designation {
  font-size: 1.2rem;
  color: #FF6347; /* Tomato color */
  margin-bottom: 10px;
}

.team-email {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

.team-description {
  font-size: 1rem;
  color: #777;
  line-height: 1.6;
}

/* Mobile Responsive Code */
@media screen and (max-width: 768px) {
  .team-section {
    padding: 30px 15px;
  }

  .team-title {
    font-size: 2rem;
    margin-top: 10px;
  }

  .team-image {
    width: 120px;
    height: 120px;
    margin-bottom: 15px;
  }

  .team-name {
    font-size: 1.2rem;
  }

  .team-designation {
    font-size: 1rem;
  }

  .team-description {
    font-size: 0.9rem;
  }
}

.see-all-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #2F4F4F;
  background-color: #FF6347;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.see-all-btn:hover {
  background-color: #e5533d;
}

/* Ensure the grid layout is responsive */
@media (max-width: 768px) {
  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Styles for the Main Team Page */
.main-team-hero-section {
  background-color: rgba(245, 236, 211, 1); /* Light beige */
  width: 100%;
  padding: 60px 0;
  text-align: center;
}

.main-team-hero-title {
  font-size: 3rem;
  font-weight: bold;
  color: rgba(47, 79, 79, 1); /* Dark slate gray */
  margin-top: 20px;
  font-family: Playfair Display;
  padding : 0;
}

.main-team-grid-section {
  background-color: rgba(245, 236, 211, 1); /* Light beige */
  padding: 0px;
}