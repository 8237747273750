/* 🌟 Base Navbar Styles */
.custom-navbar {
  background: #2F4F4F;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 1;
  z-index: 1000;
}
 
/* Navbar Container */
.container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
 
/* Navbar Brand (Logo) */
.navbar-logo {
  height: 100px;
  padding: 4px;
}
 
/* Navigation Links */
/* Navbar Links */
.navbar-nav .nav-link {
  color: #FFE4C4 !important; /* ✅ Sets text color */
  font-size: 1rem;
  transition: color 0.3s ease;
  padding: 0 30px 0 30px;
}
 
.navbar-nav .nav-link:hover {
  color: #FFE4C4!important; /* Optional: Gold hover effect */
  background-color: #FF6347 !important;
  border-radius: 120px;
  padding: 0 30px 0 30px;
}

.nav-item {
  margin: 0 15px;
}
 
 
/* Login Button */
.btn-outline-light {
  padding: 12px 30px;
  border-radius: 120px;
  background-color: #FF6347;
  color: var(--Neutral-01, #FFF);
  transition: background-color 0.3s ease, border 0.3s ease;
  border: 1px solid #FFE4C4;  /* ✅ White Border */
}
 
/* Optional: Hover Effect */
.btn-outline-light:hover {
  border: 1px solid #FF6347; /* Changes border color on hover */
  color: var(--Neutral-01, #2F4F4F);
  background-color: #FFE4C4;
}
 
 
/* 🟢 HAMBURGER MENU */
.navbar-toggler {
  display: none;
  background: none;
  border: none;
  font-size: 1.8rem;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  margin-left: auto; /* Push toggle button to the right */
  padding: 0 10px; /* Add padding for better spacing */
}
 
 
/* 📱 MOBILE RESPONSIVENESS */
@media (max-width: 991px) { /* Adjusted for iPads & Phones */
  /* Show Hamburger Icon */
  .navbar-toggler {
    display: block;
  }

  /* Hide Menu by Default on Mobile */
  .collapse {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 80px; /* Adjusted top position to avoid overlap */
    left: 0;
    width: 100%;
    background: #2F4F4F;
    text-align: center;
    padding: 10px 0;
  }

  /* Show Menu when Active */
  .collapse.show {
    display: flex;
  }

  /* Stack Items in Mobile View */
  .navbar-nav {
    flex-direction: column;
    align-items: center;
  }

  .nav-item {
    margin: 10px 0;
  }

  /* Adjust Toggle Button Alignment */
  .navbar-toggler {
    margin-left: auto; /* Push toggle button to the right */
    padding: 0 10px; /* Add padding for better spacing */
  }
}